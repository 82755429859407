import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import './About.css';

export const About = () => {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
          <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: 'url(images/banner_historia.png)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>      
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col">
                          <div className="banner-heading">
                              <h1 className="banner-title">Nuestra <span>Historia</span></h1>
                              <ol className="breadcrumb">
                                  <li>Pinturas Promex</li>
                                  <li><a href="#">Historia</a></li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
          <section id="main-container" className="main-container pb-0">
              <div className="ts-about-us">
                  <div className="container">
                      <div className="row overflow-hidden no-gutters">
                          <div className="col-lg-7 col-md-12">
                              <div className="box-skew-hidden-left">
                                  <div className="box-skew-left">
                                      <img className="img-fluid" src="images/banner_puebla.png" alt="" />
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-5 col-md-12">
                              <div className="box-skew-right">
                                  <div className="box-content-wrapper">
                                      <i className="icon-repair mt-5"></i>
                                      <h2 className="column-title no-border mt-5">
                                          <span>Pinturas Promex</span> fue fundada en 1978
                                      </h2>
                                      <p style={{fontSize: '22px', lineHeight: '25px'}}>Pinturas Promex inició operaciones en la Ciudad de Puebla, dos años más tarde cambió sus instalaciones al Estado de México donde se fabrica pintura vinílica, recubrimientos arquitectónicos, industriales, automotivos y otras especialidades.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    
          <OwlCarousel
                    className="owl-theme ts-slider-area mt-5 mb-5 container"
                    items={1}
                    loop={true}
                    smartSpeed={900}
                    dots={false}
                    nav={true}
                    navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
                    autoplay={false}
                    mouseDrag={true}
                    responsive={{
                    0: {
                        nav: false,
                        mouseDrag: false,
                    },
                    600: {
                        nav: false,
                        mouseDrag: false,
                    },
                    1000: {
                        nav: true,
                        mouseDrag: true,
                    }
                    }}
                >

                <div className="slider-items slider-overlay" style={{ background: "url(images/fondo_slider_nosotros.png)" }}>
                  <div className="container">
                      <div className="row align-items-center">
                          <div className="col-lg-12 col-md-12 p-5">
                              <div className="slider-content text-center">
                                  <div className="row">
                                      <div className="col-12 col-lg-5">                            
                                          <img src="/images/leon_mision.webp" height={350}/>
                                      </div>
                                      <div className="col-12 col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                          <p className="slider-desc">Ser una empresa donde nuestro principal interés sea satisfacer a nuestros clientes con pinturas de calidad, para obtener una retribución significativa, que permita el desarrollo de la empresa y de quienes trabajamos aquí.</p>
                                      </div>
                                  </div>
                              </div> 
                          </div> 
                      </div> 
                  </div> 
              </div> 
    
              <div className="slider-items slider-overlay" style={{ background: "url(images/fondo_slider_nosotros.png)" }}>
                  <div className="container">
                      <div className="row align-items-center">
                          <div className="col-lg-12 col-md-12 p-5">
                              <div className="slider-content text-center">
                                  <div className="row">
                                      <div className="col-12 col-lg-5">                            
                                          <img src="/images/leon_vision.webp" height={350}/>
                                      </div>
                                      <div className="col-12 col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                          <p className="slider-desc">Cumplir las expectativas de nuestros clientes con productos y servicios de alta calidad, con el compromiso y la responsabilidad de todos los que trabajamos aquí, para obtener resultados que nos permitan aumentar nuestra participación en el mercado y estar siempre en la mente del consumidor.</p>
                                      </div>
                                  </div>
                              </div> 
                          </div> 
                      </div> 
                  </div> 
              </div> 
    
              <div className="slider-items slider-overlay" style={{ background: "url(images/fondo_slider_nosotros.png)" }}>
                  <div className="container">
                      <div className="row align-items-center">
                          <div className="col-lg-12 col-md-12 p-5">
                              <div className="slider-content text-center">
                                  <div className="row">
                                      <div className="col-12 col-lg-4">                            
                                          <img src="/images/leon_valores.webp" height={350}/>
                                      </div>
                                      <div className="col-12 col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                          <p className="slider-desc">
                                              <ul style={{color: 'white', textAlign: 'justify', fontSize: '20px'}}>
                                                  <li> LEALTAD: Tenemos la sinceridad ante nuestros clientes de ser honestos y fieles a la obligaciones de servicio contraídas con ellos.</li>
                                                  <li> RESPONSABILIDAD: Somos un grupo de personas que le otorgamos a los clientes la seguridad de cumplir nuestras obligaciones de servicio.</li>
                                                  <li> RESPETO: Nuestra convicción es atender con cortesía la opinión de las personas o instituciones de negocios en las que tenemos relaciones de trabajo. La opinión de nuestros clientes y colaboradores siempre será tomada en cuenta.</li>
                                                  <li> HONESTIDAD: Las acciones de quienes integramos esta empresa siempre serán con rectitud e integridad.</li>
                                              </ul>
                                          </p>
                                      </div>
                                  </div>
                              </div> 
                          </div> 
                      </div> 
                  </div> 
              </div> 
    
              <div className="slider-items slider-overlay" style={{ background: "url(images/fondo_slider_nosotros.png)" }}>
                  <div className="container">
                      <div className="row align-items-center">
                          <div className="col-lg-12 col-md-12 p-5">
                              <div className="slider-content text-center">
                                  <div className="row">
                                      <div className="col-12 col-lg-4">                            
                                          <img src="/images/leon_compromiso.webp" height={350}/>
                                      </div>
                                      <div className="col-12 col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                          <p className="slider-desc">
                                              <ul style={{color: 'white', textAlign: 'justify', fontSize: '20px'}}>
                                                  <li> Satisfacción total de nuestros clientes</li>
                                                  <li> Aplicar nuestra misión y visión en todos nuestros acuerdos</li>
                                                  <li> Hacer mejor nuestro trabajo</li>
                                                  <li> Colaborar para tener un buen ambiente de trabajo</li>
                                                  <li> Fomentar el trabajo en equipo</li>
                                              </ul>
                                          </p>
                                      </div>
                                  </div>
                              </div> 
                          </div> 
                      </div> 
                  </div> 
              </div> 

         </OwlCarousel>

          <section id="ts-history-tab" className="ts-history-tab" style={{ paddingTop: "60px", paddingBottom: "0px" }}>
              <div className="container">
                  <div className="row">
                      <div className="col-lg-7 col-md-12">
                          <div className="row">
                              <div className="col-lg-12 col-md-12">
                                  <div className="tab-content ts-tab-content" id="myTabContent">
                                      <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                          <h2 className="column-title-sm" style={{ fontSize: "45px" }}>
                                              <span >35 años</span> de experiencia
                                          </h2>
                                          <p style={{ fontSize: "22px", lineHeight: "25px" }}>Se comercializan a todo lo largo de nuestro país a través de más de 200 distribuidores, tiendas de autoservicios y tiendas propias.</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-5 col-md-12 text-right">
                          <span><img className="img-fluid" src="images/banner_vision.png" alt="" /></span>
                      </div>
                  </div>
              </div>
          </section>
    
          <section id="ts-fun-facts" className="ts-fun-facts">
              <div className="container-fluid no-padding">
                  <div className="row no-gutters">
                      <div className="col-lg-6 col-md-12 align-self-center">
                          <div className="box-skew-area-left bg-blue">
                              <div className="box-skew-sm-left">
                                  <p className="fun-fact-info" style={{fontSize: '22px'}}>Actualmente el Grupo cuenta con tres plantas ubicadas en el Estado de México, Chihuahua y Guerrero. Además, cuenta con centros de distribución en toda la República Mexicana.</p>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                          <div className="box-skew-area-right">
                              <div className="box-skew-sm-right">
                                  <img src="/images/mapa.png" height="500" alt="Mapa" />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
      );
}
