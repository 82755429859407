import React, { useEffect, useState } from 'react';
import './Header.css'
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export const Header = () => {

    const [loaded, setLoaded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
    // Esta función se ejecutará una vez que el componente se haya montado
    setLoaded(true); // Marca el preloader como cargado después de que la página se haya cargado completamente
    }, []);

    const handleClick = () => {
    // Esta función maneja el click en el botón del preloader
    if (!loaded) {
        setLoaded(true); // Marca el preloader como cargado si aún no lo está
    }
    };

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const closeNavbar = () => {
        setIsOpen(false);
    };
    
  return (
    <div>
        <div id="preloader" className={loaded ? 'loaded' : ''} style={{backgroundColor: '#021025'}}>
            <div className="preloader-wrapper">
                <div className="loader">
                    <img src="/images/loader_promex.gif" height="350"></img>
                </div>
            </div>
        </div>

        <a href="https://api.whatsapp.com/send?phone=526142396065&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n" className="float" target="_blank">
            <i className="fa fa-whatsapp my-float"></i>
        </a>

        <div className="ts-top-bar d-none d-lg-block">
            <div className="top-bar-angle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-2">
                            <a className="ts-logo" href="index">
                                <img src="/images/logo.png" alt="logo" style={{ height: '100px' }}></img>
                            </a>
                        </div>
                        <div className="col-lg-7 col-md-7 d-flex align-items-center">
                            <div className="top-bar-event ts-top text-right">
                                <span>Bienvenido a Pinturas Promex</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 text-right d-flex align-items-center">
                            <div className="top-bar-social-icon ml-auto">
                                <ul>
                                    <li><a href="https://www.facebook.com/pinturaspromexnorte/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/pinturaspromex_cuu?igsh=cHI2cjhncXQ1Zzkw&utm_source=qr" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                    <li><a href="https://x.com/PINTUPROMEX?s=08" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></li>
                                    <li><a href="https://www.pinterest.com.mx/pinturaspromex/" target="_blank"><i className="fa-brands fa-pinterest"></i></a></li>
                                    <li><a href="mailto:chihuahua@pinturaspromex.com.mx"><i className="fa-solid fa-envelope"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="ts-top-bar d-lg-none bg-white py-2">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 text-center mb-2">
                        <a className="ts-logo" href="index">
                            <img src="/images/logo.png" alt="logo" style={{ height: '70px' }} />
                        </a>
                    </div>
                    <div className="col-12 text-center">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a href="https://www.facebook.com/pinturaspromexnorte/" target="_blank">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.instagram.com/pinturaspromex_cuu?igsh=cHI2cjhncXQ1Zzkw&utm_source=qr" target="_blank">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://x.com/PINTUPROMEX?s=08" target="_blank">
                                    <i className="fa-brands fa-x-twitter"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.pinterest.com.mx/pinturaspromex/" target="_blank">
                                    <i className="fa-brands fa-pinterest"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="mailto:chihuahua@pinturaspromex.com.mx">
                                    <i className="fa-solid fa-envelope"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <header className="ts-header header-default">
            <div className="ts-logo-area d-none d-sm-block">
                <div className="container" style={{ maxWidth: '1400px' }}>
                    <div className="row align-items-center">
                        <div className="col-md-12 col-sm-12">
                            <img src="/images/lineas/linea_decorativa.png" className="m-2" height="50"/>
                            <img src="/images/lineas/linea_madera.png" className="m-2" height="50"/>
                            <img src="/images/lineas/linea_industrial.png" className="m-2" height="50"/>
                            <img src="/images/lineas/linea_impermeabilizante.png" className="m-2" height="50"/>
                            <img src="/images/lineas/linea_automotriz.png" className="m-2" height="50"/>
                            <img src="/images/lineas/linea_complementos.png" className="m-2" height="50"/>
                            <img src="/images/lineas/linea_colores.png" className="m-2" height="50"/>
                            <ul className="top-contact-info ml-3">
                                <li>
                                    <span><i className="fa-solid fa-shop"></i></span>
                                    <div className="info-wrapper">
                                        <p className="info-subtitle"><Link to='/ser-distribuidor'>¡ÚNASE A LA FAMILIA PROMEX!</Link></p>
                                        <p className="info-title"><Link to='/ser-distribuidor'>Conviértase en distribuidor</Link></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-angle">
                <div className="container">
                    <nav className={`navbar navbar-expand-lg navbar-light ${isOpen ? 'show' : ''}`}>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavbar}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse justify-content-end ts-navbar ${isOpen ? 'show' : ''}`}>
                            <ul className="navbar-nav" onClick={closeNavbar}>
                                <li className="nav-item">
                                    <NavLink exact to='/' className="nav-link" activeClassName="active">Inicio</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/products' className="nav-link" activeClassName="active">Productos</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/about' className="nav-link" activeClassName="active">Historia</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/services' className="nav-link" activeClassName="active">Nuestros Servicios</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/tips' className="nav-link" activeClassName="active">Tips</NavLink>
                                </li>
                                <li className="nav-item d-block d-sm-none">
                                    <NavLink to='/ser-distribuidor' className="nav-link" activeClassName="active">Ser distribuidor</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="header-cart">
                            <div className="cart-link">
                                <form action="#">
                                    <a href="/clientes" target="_blank" title="Portal para Clientes" style={{ marginRight: '15px' }}><button type="button"><i className="fa-solid fa-user"></i></button></a>
                                    <a href="/branches" target="_blank" title="Ver sucursales de Promex"><button type="button"><i className="fa-solid fa-map-location-dot"></i></button></a>
                                    {/* <<button type="button"><i className="fa-regular fa-heart"></i></button>*/ }
                                    {/* <<button type="button"><i className="fa-solid fa-circle-half-stroke"></i></button>*/ }
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    </div>
  )
}
