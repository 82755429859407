import './App.css';
import { Footer } from './Components/Footer/Footer';
import { Header } from './Components/Header/Header';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { Index } from './Pages/Index';
import { About } from './Pages/About';
import { Services } from './Pages/Services';
import { Products } from './Pages/Products';
import { Linea } from './Pages/Linea';
import { Tips } from './Pages/Tips';
import { Gama } from './Pages/Gama';
import { Marca } from './Pages/Marca';
import { FormDistribuidor } from './Pages/FormDistribuidor';
import { Catalogues } from './Pages/Catalogues';
import { Promotions } from './Pages/Promotions';
import { Privacidad } from './Pages/Privacidad';

function App() {
    return (
        <div>
            
            <BrowserRouter basename="/">
                <Header />

                <Routes>
                    <Route path='/' element={<Index/>}/>
                    <Route path='/products' element={<Products/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/services' element={<Services/>}/>
                    <Route path='/tips' element={<Tips/>}/>
                    <Route path='/catalogues' element={<Catalogues/>}/>
                    <Route path='/linea/:id' element={<Linea/>}/>
                    <Route path='/gama/:id' element={<Gama/>}/>
                    <Route path='/marca/:id' element={<Marca/>}/>
                    <Route path='/ser-distribuidor' element={<FormDistribuidor/>}/>
                    <Route path='/promotions' element={<Promotions/>}/>
                    <Route path='/aviso-privacidad' element={<Privacidad/>}/>
                </Routes>


                <Footer />
            </BrowserRouter>
          
      </div>
    );
  }
  
export default App;
