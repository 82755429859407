import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';

import './Services.css';

export const Services = () => {

    useEffect(() => {
      // Scroll to the top when the component mounts
      window.scrollTo(0, 0);
    }, []);

    return (
        <div>
          <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: 'url(/images/banner_servicios.png)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col">
                  <div className="banner-heading">
                    <h1 className="banner-title">Nuestros <span>Servicios</span></h1>
                    <ol className="breadcrumb">
                      <li>Pinturas Promex</li>
                      <li><a href="#">Servicios</a></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <OwlCarousel
            className="ts-slider-area owl-carousel mt-5 mb-5"
            items={1}
            loop={true}
            smartSpeed={500}
            dots={false}
            nav={true}
            navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
            autoplay={true}
            mouseDrag={true}
            responsive={{
               0: {
                  nav: true,
                  mouseDrag: true,
               },
               600: {
                  nav: true,
                  mouseDrag: true,
               },
               1000: {
                  nav: true,
                  mouseDrag: true,
               }
            }}
         >
            <div className="slider-items slider-overlay" style={{backgroundImage: 'url(/images/fondo_gris_servicios.png)'}}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="slider-content">
                      <div className="row">
                        <div className="col-12 col-lg-6 col-md-6">
                          <img src="/images/leon_atencion.webp" alt="Atención" />
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                          <h1><img src="images/titulo_atencion.webp" alt="Atención" /></h1>
                          <p className="slider-desc">Cuando el cliente requiera hablar con la empresa siempre un integrante de nuestro equipo Promex estará ayudándolo para resolver, verificar y contestar cualquier duda que en el momento pueda surgir, brindándole una mayor seguridad.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-items slider-overlay" style={{backgroundImage: 'url(/images/fondo_gris_servicios.png)'}}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="slider-content">
                      <div className="row">
                        <div className="col-12 col-lg-6 col-md-6">
                          <img src="/images/leon_asesoria.webp" alt="Asesoría" />
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                          <h1><img src="images/titulo_asesoria.webp" alt="Asesoría" /></h1>
                          <p className="slider-desc">Ofrecemos ayuda a nuestros clientes para el manejo de sus negocios, con respecto al manejo del producto Promex.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-items slider-overlay" style={{backgroundImage: 'url(/images/fondo_gris_servicios.png)'}}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="slider-content">
                      <div className="row">
                        <div className="col-12 col-lg-6 col-md-6">
                          <img src="/images/leon_distribucion.webp" alt="Distribución" />
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                          <h1><img src="images/titulo_distribucion.webp" alt="Distribución" /></h1>
                          <p className="slider-desc">Contamos con un equipo de reparto que garantiza la entrega de su mercancia en su negocio.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-items slider-overlay" style={{backgroundImage: 'url(/images/fondo_gris_servicios.png)'}}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="slider-content">
                      <div className="row">
                        <div className="col-12 col-lg-6 col-md-6">
                          <img src="/images/leon_capacitacion.webp" alt="Capacitación" />
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                          <h1><img src="images/titulo_capacitacion.webp" alt="Capacitación" /></h1>
                          <p className="slider-desc">No solo vendemos pinturas de calidad; le enseñamos a usarlas como un profesional.<br></br>Nuestros consejos le brindarán las herramientas y técnicas necesarias para un mejor trabajo.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </OwlCarousel>
        </div>
      );
}
