import React, { useState, useEffect } from 'react';

export const Privacidad = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: "url(/images/banner_products.png)", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="banner-heading">
                                <h1 className="banner-title">Aviso de <span>privacidad</span></h1>
                                <ol className="breadcrumb">
                                    <li>Pinturas Promex</li>
                                    <li><a href="#">Aviso de Privacidad</a></li>
                                </ol>
                            </div> {/* Banner heading */}
                        </div>{/* Col end*/}
                    </div>{/* Row end*/}
                </div>{/* Container end*/}
            </div> {/* Banner area end*/}

            <section className="mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p style={{textAlign: 'justify'}}>
                                PINTURAS PROMEX DEL NORTE S.A. DE C.V.<br></br>
                                Aviso de Privacidad para la Protección de Datos Personales<br></br><br></br>

                                PINTURAS PROMEX DEL NORTE, Sociedad Anónima de Capital Variable, en terminos de en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares asumiendo la responsabilidad de su uso, manejo, almacenamiento y conﬁdencialidad de acuerdo a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante LFPDPPP) y con el ﬁn de darle a conocer la información relativa a las características principales del tratamiento al que serán sometidos sus Datos Personales, emite el presente Aviso de Privacidad.<br></br><br></br>
                                Términos y Condiciones<br></br>
                                1. El presente Aviso de Privacidad tiene por objeto la protección de los datos personales de los clientes de PINTURAS PROMEX DEL NORTE S.A. DE C.V.  mediante su tratamiento legítimo, controlado e informado, a efecto de garantizar su privacidad.<br></br><br></br>
                                2.- Al proporcionar sus Datos Personales por escrito, a través de una solicitud, formato en papel, formato digital, correo electrónico, o cualquier otro documento, se acepta y autoriza a PINTURAS PROMEX DEL NORTE S.A. DE C.V. a utilizar y tratar de forma automatizada tus datos personales e información suministrados, los cuales formarán parte de nuestra base de datos con la finalidad de usarlos, de forma limitativa, excepto para:<br></br>
                                •	Conocer al Cliente.<br></br>
                                •	Definir la ubicación comercial.<br></br>
                                •	Comunicación entre cliente-vendedor.<br></br>
                                •	Contacto entre almacen-recepcion-cliente.<br></br>
                                •	Enviar información de promociones, ofertas y publicidad de nuestros productos.<br></br>
                                •	Verificar, confirmar y validad su identidad ante el SAT.<br></br>
                                •	El cumplimiento de la relación convenido en la solicitud de nuestros productos o servicios.<br></br>
                                •	Administrar, operar y dar seguimiento a los servicios/ productos que solicita con nosotros.<br></br>
                                <br></br>
                                3.-	Confidencialidad. La Empresa no revelará los datos a terceros no autorizados salvo cuando dicha divulgación sea requerida por ley o mandamiento de autoridad.
                                <br></br>
                                <br></br>
                                4.-	Modificaciones. La Empresa se reserva el derecho de efectuar en cualquier momento, modificaciones en las presentes políticas de privacidad y adaptarla a novedades legislativas, jurisprudenciales, así como a prácticas del mercado.
                                <br></br>
                                El Titular acepta que PINTURAS PROMEX DEL NORTE S.A. DE C.V. también podrá obtener Datos del Titular de otros medios, como información que se obtenga de otras fuentes comercialmente disponibles, como por ejemplo directorios telefónicos, directorios laborales, tarjetas de presentación, bases de datos públicas, etc.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}