import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Shimmer } from 'react-shimmer'; // Importar Shimmer desde react-shimmer

export const Gama = () => {
    const { id } = useParams();
  
    const [marcas, setMarcas] = useState([]);
    const [gama, setGama] = useState({});
    const [loading, setLoading] = useState(true); // Estado para controlar la carga de datos
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://pinturaspromex.com/api2/web.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        action: 'consulta_marcas',
                        gama: id
                    })
                });
                const data = await response.json();
                if (data.status) {
                    setMarcas(data.marcas);
                    setGama(data.gama);
                } else {
                    console.error('Error: ', data.message);
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            } finally {
                setLoading(false); // Marca la carga como completa
            }
        };
        
        window.scrollTo(0, 0);
        fetchData();
    }, [id]); // Dependencia [id] para que se actualice al cambiar el parámetro

    return (
        <div>
            <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: "url(/images/banner_products.png)", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="banner-heading">
                                <h1 className="banner-title">
                                    {loading ? (
                                        <Shimmer width={400} height={40} />
                                    ) : (
                                        <>
                                            Gama <span>{gama.nombre_gama}</span>
                                        </>
                                    )}
                                </h1>
                                <ol className="breadcrumb">
                                    <li>Pinturas Promex</li>
                                    <li><a href="#">Productos</a></li>
                                </ol>
                            </div> {/* Banner heading */}
                        </div>{/* Col end*/}
                    </div>{/* Row end*/}
                </div>{/* Container end*/}
            </div> {/* Banner area end*/}

            <section id="ts-intro" className="ts-intro pb-0 mb-5 mt-1">
                <div className="container">
                    <div className="row no-gutters justify-content-center">
                        {loading ? (
                            Array.from({ length: 6 }).map((_, index) => (
                                <div key={index} className="col-6 col-lg-2 col-md-12" style={{ padding: "5px", display: "flex", flexDirection: "column" }}>
                                    <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                                        <Shimmer width="100%" height="210px" />
                                    </div>
                                </div>
                            ))
                        ) : (
                            marcas.map((marca) => (
                                <div key={marca.id} className="col-6 col-lg-2 col-md-12" style={{ padding: "5px", display: "flex", flexDirection: "column" }}>
                                    <Link to={`/marca/${marca.id}`}>
                                        <img src={marca.url_imagen} height="210" style={{ textAlign: "center", objectFit: "cover" }} alt={`Marca ${marca.id}`} />
                                    </Link>
                                </div>
                            ))
                        )}
                    </div>
                    {/* Main Row End */}
                </div>
                {/* Container End */}
            </section>
        </div>
    );
};

export default Gama;
