import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'

export const FormDistribuidor = () => {

    const initialFormData = {
        name: '',
        phone: '',
        email: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            setSubmitting(true);

            const response = await fetch('https://pinturaspromex.com/api2/web.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: 'guardar_formulario',
                    ...formData
                })
            });

            if (response.status) {
                    Swal.fire({
                        title: "Éxito",
                        text: "Tu mensaje ha sido enviado con éxito.",
                        icon: "success"
                    });

                    setFormData(initialFormData);
            } else {
                // Error al enviar el formulario
                console.error('Error al enviar el mensaje');
                // Aquí puedes mostrar un mensaje de error al usuario
            }
        } catch (error) {
            console.error('Error:', error);
            // Manejo de errores en caso de que falle la solicitud fetch
        } finally {
            setSubmitting(false);
        }
    };
    
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

  return (
    <div>
          <div className="banner-area bg-overlay" id="banner-area" style={{backgroundImage: 'url(images/banner_servicios.png)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col">
                  <div className="banner-heading">
                    <h1 className="banner-title">Ser <span>Distribuidor</span></h1>
                    <ol className="breadcrumb">
                      <li>Pinturas Promex</li>
                      <li><a href="#">Ser Distribuidor</a></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section id="main-container" className="main-container ts-contact-us">
            <div className="container">
               
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="section-title">
                            <span>Envíe su información y nos pondremos en contacto con usted.</span> 
                        </h2>
                        <div id="contact-form" className="form-container contact-us">
                            {/* START copy section: Hotel Contact Form */}
                            <form className="contactMe" onSubmit={handleSubmit} method="POST" enctype="multipart/form-data">
                                <section>
                                    <div className="form-row">
                                        <div className="col-lg-6">
                                            <input type="text" name="name" className="field" value={formData.name} onChange={handleChange}  placeholder="Nombre" required />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="tel" name="phone" className="field" value={formData.phone} onChange={handleChange}  placeholder="Teléfono" required />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="email" name="email" className="field" value={formData.email} onChange={handleChange}  placeholder="Correo electrónico" required />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <textarea name="message" className="field" value={formData.message} onChange={handleChange}  placeholder="Pequeña descripción de su negocio (incluya su giro comercial y su ubicación)" required></textarea>
                                        </div>
                                    </div>
                                  
                                    <div className="msg"></div>
                                    <button className="btn btn-primary" type="submit">Enviar mensaje</button>
                                </section>
                                {/* Ection end */}
                            </form>
                            {/* END copy section:Service Contact Form */}
                        </div>
                        {/* Contact form end */}
                    </div>
                </div>
            </div>
            {/* Container end */}
        </section>
    
    </div>
  )
}
