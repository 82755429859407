import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

import './Index.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Index = () => {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

      const isMobile = window.innerWidth <= 768; // Puedes ajustar el ancho máximo según tus necesidades

  return (
    <div>

        <OwlCarousel
            className="owl-theme"
            items={1}
            loop={true}
            smartSpeed={900}
            dots={false}
            nav={true}
            navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
            autoplay={false}
            mouseDrag={true}
            responsive={{
                0: {
                    nav: false,
                    mouseDrag: false,
                    items: 1  // Mostrar solo 1 elemento en dispositivos móviles
                },
                600: {
                    nav: false,
                    mouseDrag: false,
                    items: 1
                },
                1000: {
                    nav: true,
                    mouseDrag: true,
                    items: 1
                }
            }}
        >
            <div className="slider-items slider-overlay mb-5" style={{ height: 'auto', /*marginBottom: '0px', marginLeft: '15px', marginRight: '15px', marginTop: isMobile ? '30px' : '0px'*/}}>
                {isMobile ? (
                    /*<div className="image-container">
                        <img
                            src="/images/banner_movil.png"
                            alt="Imagen"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </div>*/
                    
                    <div className="video-container">
                        <video autoPlay muted loop playsInline>
                            <source
                                src="/video/video_banner.mp4"
                                type="video/mp4"
                            />
                            Tu navegador no soporta video HTML5.
                        </video>
                    </div>
                ) : (
                    <div className="video-container">
                        <video autoPlay muted loop playsInline>
                            <source
                                src="/video/video_banner.mp4"
                                type="video/mp4"
                            />
                            Tu navegador no soporta video HTML5.
                        </video>
                    </div>
                )}
            </div>
        </OwlCarousel>

        <section id="ts-intro" className="ts-intro pb-0">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-3 col-md-12" style={{cursor: 'pointer'}}>
                        <Link to="about">
                            <div className="ts-intro-wrapper">
                                <div className="intro-content text-center">
                                    <img src="/images/leon_historia.png" style={{textAlign: 'center'}} height="200"/>
                                    <h2 className="mt-2"><span>Nuestra </span> historia</h2>
                                </div>
                                <i className="fa-solid fa-book"></i>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-12" style={{cursor: 'pointer'}}>
                        <Link to="catalogues">
                            <div className="ts-intro-wrapper highlight">
                                <div className="intro-content text-center">
                                    <img src="/images/leon_catalogos.png" style={{textAlign: 'center'}} height="200"/>
                                    <h2 className="mt-2"><span>Conozca los</span> catálogos</h2>
                                </div>
                                <i className="fa-solid fa-list"></i>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-12" style={{cursor: 'pointer'}}>
                        <a href="branches" target="_blank">
                            <div className="ts-intro-wrapper intro-carousel">
                                <div className="intro-content text-center">
                                    <img src="/images/leon_ubicacion.png" style={{textAlign: 'center'}} height="200"/>
                                    <h2 className="mt-2"><span>Ubique su</span> Sucursal</h2>
                                </div>
                                <i className="fa-solid fa-map-marker"></i>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-12" style={{cursor: 'pointer'}}>
                        <Link to="promotions">
                            <div className="ts-intro-wrapper highlight">
                                <div className="intro-content text-center">
                                    <img src="/images/leon_promociones.png" style={{textAlign: 'center'}} height="200"/>
                                    <h2 className="mt-2"><span>Nuestras</span> promociones</h2>
                                </div>
                                <i className="fa-solid fa-hashtag"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section id="" className="ts-pertner solid-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                    <h1 style={{marginTop: '40px', textAlign: 'center'}}>Orgullosos de Nuestras Marcas</h1>

                    <OwlCarousel
                            className="ts-slider-area owl-carousel mt-5 mb-5"
                            items={5}
                            loop={true}
                            smartSpeed={900}
                            dots={false}
                            nav={true}
                            navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
                            autoplay={true}
                            mouseDrag={true}
                            responsive={{
                            0: {
                                nav: true,
                                mouseDrag: true,
                                items: 2  // Mostrar solo 1 elemento en dispositivos móviles
                            },
                            600: {
                                nav: true,
                                mouseDrag: true,
                            },
                            1000: {
                                nav: true,
                                mouseDrag: true,
                            }
                            }}
                        >
                           

                        <figure className="slider-item partner-logo">
                            <Link to={`/products`}><img className="img-fluid p-4" src="/images/marcas/promex.png" alt=""></img></Link>
                        </figure>
                        <figure className="slider-item partner-logo">
                            <Link to={`/products`}><img className="img-fluid p-4" src="/images/marcas/madera.png" alt=""></img></Link>
                        </figure>
                        <figure className="slider-item partner-logo">
                            <Link to={`/products`}><img className="img-fluid p-4" src="/images/marcas/hermes.png" alt=""></img></Link>
                        </figure>
                        <figure className="slider-item partner-logo">
                            <Link to={`/products`}><img className="img-fluid p-4" src="/images/marcas/impertron.png" alt=""></img></Link>
                        </figure>


                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
